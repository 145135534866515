import { Col, Spin } from 'antd';
import styles from '../../Dashboard.module.css';
import { useEffect, useState } from 'react';
import { Bar } from 'react-chartjs-2';
interface Top10DashboardCardProps {
    header: string;
    data: any;
    backgroundColor: string;
    labels: string[];
    datasetLabel: string;
    isLoading: boolean;
}
const Top10DashboardCard = (props: Top10DashboardCardProps) => {
    const graphOptions = {
        indexAxis: 'y' as const,
        responsive: true,
        plugins: {
          legend: {
            display: false,
            // position: 'top' as const,
            // align: 'start' as const
          },
          title: {
            display: false,
          },
        },
    };

    const [header, setHeader] = useState<string>(props.header);
    const [data, setData] = useState<string>(props.data);
    const [labels, setLabels] = useState<string[]>(props.labels);
    const [datasetLabel, setDatasetLabel] = useState<string>("");
    const [isLoading, setIsLoading] = useState<boolean>(true);

    useEffect(() => {
        setHeader(props.header);
    }, [props.header])
    useEffect(() => {
        setData(props.data);
    }, [props.data])
    useEffect(() => {
        setLabels(props.labels);
    }, [props.labels])
    useEffect(() => {
        setDatasetLabel(props.datasetLabel);
    }, [props.datasetLabel])
    useEffect(() => {
        setIsLoading(props.isLoading);
    }, [props.isLoading])
    return (
        <Col 
            className={styles['top10-dashboard-card']} 
            xs={{ flex: '100%' }}
            sm={{ flex: '48%' }}
            md={{ flex: '31%' }}
            lg={{ flex: '31%' }}
            xl={{ flex: '31%' }}
        >
            <div style={{display: 'flex', justifyContent: 'center'}}>
                <h3 style={{margin: 0, marginBottom: 8, color: '#17cd08'}}>
                    {header}
                </h3>
            </div>
            <div style={{display: 'flex', justifyContent: 'center'}}>
                {
                    isLoading ? 
                    (
                        <Spin 
                            style={{height: 400}}
                            size={"large"} 
                            className={styles['spin-div']}
                            rootClassName={styles['spin-div']}
                            wrapperClassName={styles['spin-div']}
                        /> 
                    )
                    :
                    (
                        <Bar
                            height={400}
                            options={graphOptions}
                            data={
                                {
                                    labels: labels,
                                    datasets: [
                                        {
                                            label: datasetLabel,
                                            data: data,
                                            backgroundColor: props.backgroundColor,
                                        }
                                    ]
                                }
                            }
                            // {...props}
                        />
                    )
                }
            </div>
        </Col>
    )
}

export default Top10DashboardCard;