import { Radio, RadioChangeEvent } from "antd";
import styles from "../Dashboard.module.css";
import { useEffect, useState } from "react";

interface BusinessSelectionProps {
    currentBusiness: "1" | "2";
    onBusinessChange: (newBusiness: "1" | "2") => void;
}

const BusinessSelection = (props: BusinessSelectionProps) => {
    const [selectedBusiness, setSelectedBusiness] = useState<"1" | "2">(props.currentBusiness);

    useEffect(() => {
        setSelectedBusiness(props.currentBusiness);
    }, [props.currentBusiness])
    const onBusinessChange = (e: RadioChangeEvent) => {
        console.log(`radio checked: ${e.target.value}`);
        if(props.onBusinessChange != null){
            props.onBusinessChange(e.target.value);
        }
    }
    return (
        <div className={styles['business-container']}>
            <Radio.Group 
                onChange={onBusinessChange} 
                value={selectedBusiness}
                style={{width: '100%', textAlign: 'center', marginTop: 8}}
            >
                <Radio.Button style={{width: 120, textAlign: 'center'}} value="1">ขนส่ง</Radio.Button>
                <Radio.Button style={{width: 120, textAlign: 'center'}} value="2">ลิสซิ่ง</Radio.Button>
                <Radio.Button style={{width: 120, textAlign: 'center'}} value="3">นิ่มซิตี้เดลี่</Radio.Button>
            </Radio.Group>
        </div>
    )
}

export default BusinessSelection;