import logo from '../../logo.svg';
const Home = () => {
    return (
        <div className="App">
          <header className="App-header">
            <img src={logo} className="App-logo" alt="logo" />
            <p>
              Edit <code>src/App.tsx</code> and save to reload.
            </p>
            <a
              className="App-link"
              href="/dashboard"
              target="_blank"
              rel="noopener noreferrer"
            >
              Go to Dashboard
            </a>
          </header>
        </div>
    )
}

export default Home;