
import { useEffect, useState } from "react";
import { ReportItemGroupByYearMonth } from "../../../../helpers/DataHelper";
import styles from "../../Dashboard.module.css";
import { currencyFormat } from "../../../../helpers/NumberFormatHelper";
import TextDashboardFooterCard from "./TextDashboardFooterCard";
import { Spin } from "antd";

interface TextDashboardFooterProps{
    currentBusiness: "1" | "2";
    currentDashboardData: ReportItemGroupByYearMonth | null;
}
const TextDashboardFooter = (props: TextDashboardFooterProps) => {
    const [currentDashboardData, setCurrentDashboardData] = useState<ReportItemGroupByYearMonth | null>(props.currentDashboardData);

    useEffect(() => {
        setCurrentDashboardData(props.currentDashboardData)
    }, [props.currentDashboardData])
    const getCurrencyData2Text = (input: number | undefined | null) => {
        return input == null ? <Spin /> : currencyFormat(input, 0);
    }
    return (
        <div className={styles['text-dashboard-footer']}>
            <TextDashboardFooterCard label={"จำนวนห้องทั้งหมด"} value={getCurrencyData2Text(currentDashboardData == null ? null : currentDashboardData.summaryAllRoom)} />
            <TextDashboardFooterCard label={"ห้องที่มีการเช่า"} value={getCurrencyData2Text(currentDashboardData == null ? null : currentDashboardData.summaryOnRentRoom)} />
            <TextDashboardFooterCard label={"ห้องว่าง"} value={getCurrencyData2Text(currentDashboardData == null ? null : currentDashboardData.summaryEmptyRoom)} />
            {/* <div className={styles['text-dashboard-footer-data']}>จำนวนห้องทั้งหมด</div>
            <div className={styles['text-dashboard-footer-data']}>ห้องที่มีการเช่า</div>
            <div className={styles['text-dashboard-footer-data']}>ห้องว่าง</div> */}
        </div>
    )
}

export default TextDashboardFooter;