import { ReportItem } from "../models/DashboardModels";
import { convertMonthNo2MonthName } from "./DateTimeHelper";

export interface ReportItemGroupByYearMonth{
    year: string;
    month: string;
    reportItems: ReportItem[];
    summaryIncome? : number;
    summaryPaid? : number;
    summaryOverdue? : number;
    summaryAllRoom?: number;
    summaryOnRentRoom?: number;
    summaryEmptyRoom?: number;
}
export interface ReportItemGroupByPropertyGrp{
    propGrpId: number;
    propGrpName: string;
    reportItems: ReportItem[];
    summaryIncome? : number;
    summaryPaid? : number;
    summaryOverdue? : number;
    summaryAllRoom?: number;
    summaryOnRentRoom?: number;
    summaryEmptyRoom?: number;
}

export interface BarGraphModel{
    data: BarGraphDataModel[];
    label: string[];
}

export interface BarGraphDataModel{
    label: string,
    data: number[],
    backgroundColor: string,
}

export const adjustYearMonth = (dataList: ReportItem[]) => {
    const groupList: ReportItemGroupByYearMonth[] = [];
    dataList.forEach((value, index) => {
        const year = value.year;
        const month = value.month;
        let findGroupIndex = groupList.findIndex((gVal) => {return gVal.year == year && gVal.month == month});
        if(findGroupIndex > -1){
            groupList[findGroupIndex].reportItems.push(value);
        }else{
            groupList.push({
                year: year,
                month: month,
                reportItems: [value]
            });
        }
    })
    const sortGroupDataList: ReportItemGroupByYearMonth[] = sortGroupList(groupList);
    const summaryGroupDataList: ReportItemGroupByYearMonth[] = calculateSummary(sortGroupDataList) as ReportItemGroupByYearMonth[];
    return summaryGroupDataList;
}

export const calculateSummary = (dataList: ReportItemGroupByYearMonth[] | ReportItemGroupByPropertyGrp[]) => {
    dataList.forEach((val, inx) => {
        let summaryIncome: number = 0;
        let summaryPaid: number = 0;
        let summaryOverdue: number = 0;
        let summaryAllRoom: number = 0;
        let summaryOnRentRoom: number = 0;
        let summaryEmptyRoom: number = 0;
        val.reportItems.forEach((item, ind) => {
            summaryIncome += item.totalAmt;
            summaryPaid += item.payAmt;
            summaryOverdue += item.notPayAmt;
            summaryAllRoom += item.allRoomQty;
            summaryOnRentRoom += item.onRentRoomQty;
            summaryEmptyRoom += item.emptyRoomQty;
        })
        val.summaryIncome = summaryIncome;
        val.summaryPaid = summaryPaid;
        val.summaryOverdue = summaryOverdue;
        val.summaryAllRoom = summaryAllRoom;
        val.summaryOnRentRoom = summaryOnRentRoom;
        val.summaryEmptyRoom = summaryEmptyRoom;

    });
    return dataList;

}

export const generateBarGraphData = (dataList: ReportItemGroupByYearMonth[]) => {
    const resultData = [
        {
            label: 'รายได้ทั้งหมด',
            data: dataList.map((val) => {return val.summaryIncome ? val.summaryIncome : 0}),
            backgroundColor: 'rgba(31, 114, 249, 0.9)',
        },
        {
            label: 'ชำระเงินแล้ว',
            data: dataList.map((val) => {return val.summaryPaid ? val.summaryPaid : 0}),
            backgroundColor: 'rgba(109, 209, 107, 0.9)',
        },
        {
            label: 'ค้างชำระ',
            data: dataList.map((val) => {return val.summaryOverdue ? val.summaryOverdue : 0}),
            backgroundColor: 'rgba(122, 149, 171, 0.9)',
        }
    ]
    const resultLabel: string[] = dataList.map((val) => {return convertMonthNo2MonthName(val.month)});
    let barGraph: BarGraphModel = {
        data: resultData,
        label: resultLabel
    }
    return barGraph;
}

const sortGroupList = (groupList: ReportItemGroupByYearMonth[]) => {
    return groupList.sort((a, b) => {return a.year.localeCompare(b.year) == 0 ? a.month.localeCompare(b.month) : a.year.localeCompare(b.year)})
}

export const adjustPropGrp = (dataList: ReportItem[]) => {
    const groupList: ReportItemGroupByPropertyGrp[] = [];
    dataList.forEach((value, index) => {
        const propGrpId = value.propGrpId;
        const propGroupName = value.propGroupName;
        let findGroupIndex = groupList.findIndex((gVal) => {return gVal.propGrpId == propGrpId});
        if(findGroupIndex > -1){
            groupList[findGroupIndex].reportItems.push(value);
        }else{
            groupList.push({
                propGrpId: propGrpId,
                propGrpName: propGroupName,
                reportItems: [value]
            });
        }
    })
    const sortGroupDataList: ReportItemGroupByPropertyGrp[] = sortPropGroupList(groupList);
    const summaryGroupDataList: ReportItemGroupByPropertyGrp[] = calculateSummary(sortGroupDataList) as ReportItemGroupByPropertyGrp[];
    return summaryGroupDataList;
}
const sortPropGroupList = (groupList: ReportItemGroupByPropertyGrp[]) => {
    return groupList.sort((a, b) => {return a.propGrpId - b.propGrpId});
}