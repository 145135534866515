import axios from "axios";
import ApiConfig from "../constants/ApiConfig"

export const loadReport = async (params: any) => {
    let url = `${ApiConfig.urlRoot}/reports`;
    // {
    //     businessId: 1
    //     from: {year: "2024", month: "04"}
    //     to: {year: "2024", month: "04"}
    // }
    let res = await axios.post(url, params);
    if(res.status === 200){
        return res.data;
    }else{
        return null;
    }
}

export const loadPropertyGroup = async (params: any) => {
    let url = `${ApiConfig.urlRoot}/propertygroups`;
    let res = await axios.post(url, params);
    if(res.status === 200){
        return res.data;
    }else{
        return null;
    }
}