import { Bar } from "react-chartjs-2";
import styles from "../Dashboard.module.css";
import { Checkbox, CheckboxProps, Select, Spin } from "antd";
import { DashboardSearchParam, PropertyGroup, ReportItem } from "../../../models/DashboardModels";
import { loadPropertyGroup, loadReport } from "../../../services/DashboardService";
import { useEffect, useState } from "react";
import { BarGraphModel, ReportItemGroupByYearMonth, adjustYearMonth, generateBarGraphData } from "../../../helpers/DataHelper";
import { CheckboxValueType } from "antd/es/checkbox/Group";
import moment from "moment";
import { convertMonthNo2MonthName } from "../../../helpers/DateTimeHelper";

interface GraphSearchSectionProps {
    currentBusiness: "1" | "2";
    onUpdateCurrentReport: (param: ReportItemGroupByYearMonth[]) => void
    onUpdateRawData: (newRawData: ReportItem[]) => void
    onUpdateIsLoading: (newLoadingStatus: boolean) => void
}
const GraphSearchSection = (props: GraphSearchSectionProps) => {
    const labels = ['January', 'February', 'March', 'April', 'May', 'June', 'July'];
    const monthValues = ['01', '02', '03','04', '05', '06','07', '08', '09','10', '11', '12',]

    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [propertyGroup, setPropertyGroup] = useState<PropertyGroup[]>([]);
    const [yearOption, setYearOption] = useState<string[]>([]);
    const [selectedYearOption, setselectedYearOption] = useState<string>(moment().format("YYYY"));
    const [selectedFromMonthOption, setselectedFromMonthOption] = useState<string>(moment().format("MM"));
    const [selectedToMonthOption, setselectedToMonthOption] = useState<string>(moment().format("MM"));
    const [currentBusiness, setCurrentBusiness] = useState<"1" | "2">(props.currentBusiness);
    const [countPlace, setCountPlace] = useState<number>(0);
    const [oriRawData, setOriRawData] = useState<ReportItem[]>([]);
    const [rawData, setRawData] = useState<ReportItem[]>([]);
    const [currentDashboardData, setCurrentDashboardData] = useState<ReportItemGroupByYearMonth[]>([]);
    const [checkedList, setCheckedList] = useState<any[]>([]);
    const [searchParam, setSearchParam] = useState<DashboardSearchParam | null>(null);
    const [currentGraphData, setCurrentGraphData] = useState<BarGraphModel>({
        data: [
            {
                label: 'รายได้ทั้งหมด',
                data: [100, 200, 50, 300, 250, 75, 250],
                backgroundColor: 'rgba(31, 114, 249, 0.9)',
            },
            {
                label: 'ชำระเงินแล้ว',
                data: [100, 50, 45, 278, 75, 72, 220],
                backgroundColor: 'rgba(109, 209, 107, 0.9)',
            },
            {
                label: 'ค้างชำระ',
                data: [0, 150, 5, 22, 175, 3, 30],
                backgroundColor: 'rgba(122, 149, 171, 0.9)',
            }
        ],
        label: labels
    });
    // const []

    const graphOptions = {
        responsive: true,
        plugins: {
          legend: {
            position: 'top' as const,
            align: 'start' as const
          },
          title: {
            display: false,
          },
        },
    };

    useEffect(() => {
        let currentYearStr = moment().format("YYYY");
        let currentYearInt = parseInt(currentYearStr);
        let initYearOption: string[] = [];
        for(let i = 0; i < 8; i++){
            initYearOption.push(`${currentYearInt - i}`);
        }
        setYearOption(initYearOption);
    }, [])
    useEffect(() => {
        setCurrentBusiness(props.currentBusiness);
    }, [props.currentBusiness])
    useEffect(() => {
        if(currentBusiness != null && selectedYearOption != null && selectedFromMonthOption != null && selectedToMonthOption){
            setSearchParam({
                businessId: parseInt(currentBusiness),
                from: {year: selectedYearOption, month: selectedFromMonthOption},
                to: {year: selectedYearOption, month: selectedToMonthOption},
            })
        }
    }, [currentBusiness, selectedYearOption, selectedFromMonthOption, selectedToMonthOption])
    useEffect(() => {
        if(searchParam != null){
            setIsLoading(true);
            getPropertyGroup(searchParam);
            getReports(searchParam).finally(() => {
                setIsLoading(false);
            });
        }
    }, [searchParam])

    useEffect(() => {
        convert2BarGraphData(currentDashboardData).then(() => {

        })
    }, [currentDashboardData]);

    useEffect(() => {
        // setRawData(oriRawData);
        console.log("oriRawData: ", oriRawData, ", checkedList: ", checkedList);
        if(oriRawData != null && checkedList != null){
            filterRawData(oriRawData, checkedList);
        }
    }, [oriRawData, checkedList])
    useEffect(() => {
        let resultList: ReportItemGroupByYearMonth[] = adjustYearMonth(rawData);

        setCurrentDashboardData(resultList);

        if(props.onUpdateCurrentReport != null){
            props.onUpdateCurrentReport(resultList);
        }

        if(props.onUpdateRawData != null){
            props.onUpdateRawData(rawData);
        }
    }, [rawData])

    useEffect(() => {
        if(props.onUpdateIsLoading != null){
            props.onUpdateIsLoading(isLoading);
        }
    }, [isLoading])

    useEffect(() => {
        if(propertyGroup){
            setCountPlace(propertyGroup.length);
            setCheckedList(propertyGroup.map((val) => {return val.id}))
        }else{
            setCountPlace(0);
        }
    }, [propertyGroup])

    const filterRawData = (originalRawData: ReportItem[], filterDataList: any[]) => {
        console.log("filterRawData: ", originalRawData, filterDataList);
        let filterData = originalRawData.filter((val) => {return filterDataList.findIndex((chkId) => {return chkId == val.propGrpId}) > -1});
        console.log("filtered: ", filterData);
        setRawData(filterData);
    }

    const isPropertyGrpFoundInData = (propGrpId: number) => {
        return oriRawData.findIndex((val) => {return val.propGrpId == propGrpId}) > -1;
    }

    const convert2BarGraphData = async (dataList: ReportItemGroupByYearMonth[]) => {
        let barGraphData: BarGraphModel = generateBarGraphData(dataList);
        setCurrentGraphData(barGraphData);
    }

    const getPropertyGroup = async (searchParam: DashboardSearchParam) => {
        let resData:PropertyGroup[]  = await loadPropertyGroup(searchParam);
        setPropertyGroup(resData);
    }

    const getReports = async (searchParam: DashboardSearchParam) => {
        let resData: any[]  = await loadReport(searchParam);
        setOriRawData(resData);
    }
    const onCheckAllChange: CheckboxProps['onChange'] = (e) => {
      setCheckedList(e.target.checked ? propertyGroup.map((val) => {return val.id}) : []);
    };
    const onChange = (list: CheckboxValueType[]) => {
      setCheckedList(list);
    };
    const indeterminate = checkedList.length > 0 && checkedList.length < propertyGroup.length;
    const checkAll = checkedList.length == propertyGroup.length;
    const disableFromMonthOption = (optValue: string) => {
        let optValueInt = parseInt(optValue);
        let selectedToValueInt = parseInt(selectedToMonthOption);
        return optValueInt > selectedToValueInt;
    }
    const disableToMonthOption = (optValue: string) => {
        let optValueInt = parseInt(optValue);
        let selectedFromValueInt = parseInt(selectedFromMonthOption);
        return optValueInt < selectedFromValueInt;
    }
    return (
        <div className={styles['bottom-dashboard-container']}>
            <div className={styles['graph-dashboard-container']}>
                {
                    isLoading ? 
                    <Spin 
                        size={"large"} 
                        className={styles['spin-div']}
                        rootClassName={styles['spin-div']}
                        wrapperClassName={styles['spin-div']}
                    />
                    // <div className={styles['spin-div']}>
                    //     <Spin 
                    //         size={"large"} 
                    //         wrapperClassName={styles['spin-container']}
                    //     >
                    //         <Bar
                    //             options={graphOptions}
                    //             data={
                    //                 {
                    //                     labels: currentGraphData.label,
                    //                     datasets: currentGraphData.data
                    //                 }
                    //             }
                    //             // {...props}
                    //         />
                    //     </Spin> 
                    // </div>
                    : 
                    <Bar
                        options={graphOptions}
                        data={
                            {
                                labels: currentGraphData.label,
                                datasets: currentGraphData.data
                            }
                        }
                        // {...props}
                    />
                }
            </div>
            <div className={styles['property-list-container']}>
                <div className={styles['search-container']}>
                    <div className={styles['search-row']}>
                        <div className={styles['search-label']}>
                            ปี:
                        </div>
                        <div className={styles['search-combo']}>
                            <Select value={selectedYearOption} style={{width: '100%'}} onChange={(value) => {setselectedYearOption(value)}}>
                                {
                                    yearOption.map((year) => {
                                        return <Select.Option value={year}>{year}</Select.Option>
                                    })
                                }
                                {/* <Select.Option value={2020}>2020</Select.Option>
                                <Select.Option value={2021}>2021</Select.Option>
                                <Select.Option value={2022}>2022</Select.Option>
                                <Select.Option value={2023}>2023</Select.Option>
                                <Select.Option value={2024}>2024</Select.Option> */}
                            </Select>
                        </div>
                    </div>
                    <div className={styles['search-row']}>
                        <div className={styles['search-label']}>
                            เดือน จาก:
                        </div>
                        <div className={styles['search-combo']}>
                            <Select 
                                value={selectedFromMonthOption} 
                                style={{width: '100%'}}
                                onChange={(value: string) => {setselectedFromMonthOption(value);}}
                            >
                                {
                                    monthValues.map((val) => {
                                        return <Select.Option value={val} disabled={disableFromMonthOption(val)}>{convertMonthNo2MonthName(val)}</Select.Option>
                                    })
                                }
                            </Select>
                        </div>
                    </div>
                    <div className={styles['search-row']}>
                        <div className={styles['search-label']}>
                            ถึง: 
                        </div>
                        <div className={styles['search-combo']}>
                            <Select 
                                value={selectedToMonthOption} 
                                style={{width: '100%'}}
                                onChange={(value: string) => {setselectedToMonthOption(value);}}
                            >
                            {
                                monthValues.map((val) => {
                                    return <Select.Option value={val} disabled={disableToMonthOption(val)}>{convertMonthNo2MonthName(val)}</Select.Option>
                                })
                            }
                            </Select>
                        </div>
                    </div>
                </div>
                <div className={styles['property-container']}>
                    <div style={{marginLeft: 10, display: 'flex', flexDirection: 'row', marginBottom: 10}}>
                        <div style={{flex: 1}}>
                            สถานที่เช่า: {checkedList.length}/{countPlace}
                        </div>
                        <div style={{display: 'flex', flex: 1, justifyContent: 'flex-end'}}>
                            <Checkbox indeterminate={indeterminate} style={{marginRight: 15}} onChange={onCheckAllChange} checked={checkAll}>เลือกทั้งหมด</Checkbox>
                        </div>
                    </div> 
                    <Checkbox.Group className={styles['property-list']} value={checkedList} onChange={onChange}>
                        {/* <Checkbox.Group> */}
                            {propertyGroup.map((value) => {
                                return (
                                    <div>
                                        <Checkbox value={value.id} style={{color: isPropertyGrpFoundInData(value.id) ? '#000' : '#8a8a8a'}}>{value.name}</Checkbox>
                                    </div>
                                )
                            })}
                        {/* </Checkbox.Group> */}
                    </Checkbox.Group>
                </div>
            </div>
        </div>
    )
}

export default GraphSearchSection;