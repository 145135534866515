import { useEffect, useState } from 'react';
import styles from '../../Dashboard.module.css'
import TextDashboardDataCard from './TextDashboardDataCard';
import { ReportItemGroupByYearMonth } from '../../../../helpers/DataHelper';
import { currencyFormat } from '../../../../helpers/NumberFormatHelper';
import { Spin } from 'antd';

interface TextDashboardDataProps{
    currentBusiness: "1" | "2";
    currentDashboardData: ReportItemGroupByYearMonth | null;
}
const TextDashboardData = (props: TextDashboardDataProps) => {
    const [currentDashboardData, setCurrentDashboardData] = useState<ReportItemGroupByYearMonth | null>(props.currentDashboardData);

    useEffect(() => {
        setCurrentDashboardData(props.currentDashboardData)
    }, [props.currentDashboardData])
    const getCurrencyData2Text = (input: number | undefined | null) => {
        return input == null ? <Spin /> : currencyFormat(input, 2);
    }
    return (
        <div className={styles['text-dashboard-footer-data']}>
            <TextDashboardDataCard label={"รายได้ทั้งหมด"} value={getCurrencyData2Text(currentDashboardData == null ? null : currentDashboardData.summaryIncome)} />
            <TextDashboardDataCard label={"ชำระเงินแล้ว"} value={getCurrencyData2Text(currentDashboardData == null ? null : currentDashboardData.summaryPaid)} />
            <TextDashboardDataCard label={"ค้างชำระ"} value={getCurrencyData2Text(currentDashboardData == null ? null : currentDashboardData.summaryOverdue)} />
        </div>
    )
}

export default TextDashboardData;