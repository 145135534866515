import { useEffect, useState } from 'react';
import styles from '../../Dashboard.module.css';
interface TextDashboardDataCardProps {
    label: string;
    value: string;
}
const TextDashboardDataCard = (props: TextDashboardDataCardProps) => {
    const [label, setLabel] = useState<string>(props.label);
    const [value, setValue] = useState<string>(props.value);

    useEffect(() => {
        setLabel(props.label);
    }, [props.label])
    useEffect(() => {
        setValue(props.value);
    }, [props.value])

    return (
        <div className={styles['text-dashboard-data-card']}>
            <div>{label}</div>
            <div style={{display: 'flex', width: '100%', justifyContent: 'flex-end', alignItems: 'center'}}>
                <span className={styles['text-dashboard-data-card-span']}>{value}</span>
            </div>
        </div>
    )
}

export default TextDashboardDataCard;