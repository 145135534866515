import { useEffect, useState } from "react"
import { loadPropertyGroup } from "../../services/DashboardService"
import styles from "./Dashboard.module.css"
import { DashboardSearchParam, PropertyGroup, ReportItem } from "../../models/DashboardModels";
import { Divider, Radio, RadioChangeEvent } from "antd";
import BusinessSelection from "./nested/BusinessSelection";
import TextDashboard from "./nested/TextDashboard";
import Top10Dashboard from "./nested/Top10Dashboard";
import GraphSearchSection from "./nested/GraphSearchSection";
import { ReportItemGroupByYearMonth } from "../../helpers/DataHelper";
const Dashboard = () => {
    const [selectedBusiness, setSelectedBusiness] = useState<"1" | "2">("1");
    const [currentDashboardData, setCurrentDashboardData] = useState<ReportItemGroupByYearMonth[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [rawData, setRawData] = useState<ReportItem[]>([]);

    const updateIsLoading = (newLoadingStatus: boolean) => {
        setIsLoading(newLoadingStatus)
    }
    const onBusinessChange = (value: "1" | "2") => {
        setSelectedBusiness(value);
    }
    const onUpdateCurrentReport = (data: ReportItemGroupByYearMonth[]) => {
        setCurrentDashboardData(data);
    }
    const onUpdateRawData = (newRawData: ReportItem[]) => {
        setRawData(newRawData);
    }
    return (
        <div className={styles['main-container']}>
            <div className={styles['dashboard-container']}>
                <BusinessSelection 
                    currentBusiness={selectedBusiness} 
                    onBusinessChange={onBusinessChange} 
                />
                <TextDashboard 
                    currentBusiness={selectedBusiness} 
                />
                <GraphSearchSection 
                    currentBusiness={selectedBusiness} 
                    onUpdateCurrentReport={onUpdateCurrentReport} 
                    onUpdateRawData={onUpdateRawData}
                    onUpdateIsLoading={updateIsLoading}
                />
                <h2 style={{width: '100%', textAlign: 'center', marginBottom: 5}}>Top 10</h2>
                <Top10Dashboard 
                    isLoading={isLoading}
                    currentRawData={rawData}
                />
            </div>
        </div>
    )
}

export default Dashboard;