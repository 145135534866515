import { Col, Row } from "antd";
import styles from "../Dashboard.module.css"
import Top10DashboardCard from "./Top10Dashboard/Top10DashboardCard";
import { ReportItem } from "../../../models/DashboardModels";
import { useEffect, useState } from "react";
import { ReportItemGroupByPropertyGrp, adjustPropGrp } from "../../../helpers/DataHelper";

interface Top10DashboardProps {
    currentRawData: ReportItem[]
    isLoading: boolean;
}
interface Top10DashboardCardData {
    data: number[];
    labels: string[];
}
const Top10Dashboard = (props: Top10DashboardProps) => {
    const [rawData, setRawData] = useState<ReportItem[]>([]);
    const [groupDataList, setGroupDataList] = useState<ReportItemGroupByPropertyGrp[]>([]);

    const [incomeData, setIncomeData] = useState<Top10DashboardCardData>({data: [], labels: []});
    const [paidData, setPaidData] = useState<Top10DashboardCardData>({data: [], labels: []});
    const [overdueData, setOverdueData] = useState<Top10DashboardCardData>({data: [], labels: []});
    const [rentRoomData, setRentRoomData] = useState<Top10DashboardCardData>({data: [], labels: []});
    const [emptyRoomData, setEmptyRoomData] = useState<Top10DashboardCardData>({data: [], labels: []});

    const [isLoading, setIsLoading] = useState<boolean>(false);

    const labelsConst = ['January', 'February', 'March', 'April', 'May', 'June', 'July'];
    
    useEffect(() => {
        setRawData(props.currentRawData);
    }, [props.currentRawData])
    useEffect(() => {
        if(rawData != null){
            let newGroupList = adjustPropGrp(rawData);
            setGroupDataList(newGroupList);
        }
    }, [rawData])
    useEffect(() => {
        // console.log("groupDataList: ", groupDataList);
        sortIncomeData(groupDataList);
        sortPaidData(groupDataList);
        sortOverdueData(groupDataList);
        sortRentRoomData(groupDataList);
        sortEmptyRoomData(groupDataList);
    }, [groupDataList])
    useEffect(() => {
        setIsLoading(props.isLoading)
    }, [props.isLoading])

    const sortIncomeData = async (groupDataList: ReportItemGroupByPropertyGrp[]) => {
        let sortData = groupDataList.sort((a, b) => {return a.summaryIncome && b.summaryIncome ? b.summaryIncome - a.summaryIncome : a.summaryIncome ? - 1 : b.summaryIncome ? 1 : 0});
        let data: number[] = [];
        let labels: string[] = [];
        for(let i = 0; i < sortData.length && i < 10; i++){
            let rawData: number | undefined = sortData[i].summaryIncome;
            let summaryIncome: number = 0;
            if(rawData){
                summaryIncome = rawData;
            }
            data.push(summaryIncome);
            labels.push(sortData[i].propGrpName)
        }
        setIncomeData({data: data, labels: labels});
    }
    const sortPaidData = async (groupDataList: ReportItemGroupByPropertyGrp[]) => {
        let sortData = groupDataList.sort((a, b) => {return a.summaryPaid && b.summaryPaid ? b.summaryPaid - a.summaryPaid : a.summaryPaid ? - 1 : b.summaryPaid ? 1 : 0});
        let data: number[] = [];
        let labels: string[] = [];
        for(let i = 0; i < sortData.length && i < 10; i++){
            let rawData: number | undefined = sortData[i].summaryPaid;
            let summaryPaid: number = 0;
            if(rawData){
                summaryPaid = rawData;
            }
            data.push(summaryPaid);
            labels.push(sortData[i].propGrpName)
        }
        setPaidData({data: data, labels: labels});
    }
    const sortOverdueData = async (groupDataList: ReportItemGroupByPropertyGrp[]) => {
        let sortData = groupDataList.sort((a, b) => {return a.summaryOverdue && b.summaryOverdue ? b.summaryOverdue - a.summaryOverdue : a.summaryOverdue ? - 1 : b.summaryOverdue ? 1 : 0});
        let data: number[] = [];
        let labels: string[] = [];
        for(let i = 0; i < sortData.length && i < 10; i++){
            let rawData: number | undefined = sortData[i].summaryOverdue;
            let summaryOverdue: number = 0;
            if(rawData){
                summaryOverdue = rawData;
            }
            data.push(summaryOverdue);
            labels.push(sortData[i].propGrpName)
        }
        setOverdueData({data: data, labels: labels});
    }
    const sortRentRoomData = async (groupDataList: ReportItemGroupByPropertyGrp[]) => {
        let sortData = groupDataList.sort((a, b) => {return a.summaryOnRentRoom && b.summaryOnRentRoom ? b.summaryOnRentRoom - a.summaryOnRentRoom : a.summaryOnRentRoom ? - 1 : b.summaryOnRentRoom ? 1 : 0});
        let data: number[] = [];
        let labels: string[] = [];
        for(let i = 0; i < sortData.length && i < 10; i++){
            let rawData: number | undefined = sortData[i].summaryOnRentRoom;
            let summaryOnRentRoom: number = 0;
            if(rawData){
                summaryOnRentRoom = rawData;
            }
            data.push(summaryOnRentRoom);
            labels.push(sortData[i].propGrpName)
        }
        setRentRoomData({data: data, labels: labels});
    }
    const sortEmptyRoomData = async (groupDataList: ReportItemGroupByPropertyGrp[]) => {
        let sortData = groupDataList.sort((a, b) => {return a.summaryEmptyRoom && b.summaryEmptyRoom ? b.summaryEmptyRoom - a.summaryEmptyRoom : a.summaryEmptyRoom ? - 1 : b.summaryEmptyRoom ? 1 : 0});
        let data: number[] = [];
        let labels: string[] = [];
        for(let i = 0; i < sortData.length && i < 10; i++){
            let rawData: number | undefined = sortData[i].summaryEmptyRoom;
            let summaryEmptyRoom: number = 0;
            if(rawData){
                summaryEmptyRoom = rawData;
            }
            data.push(summaryEmptyRoom);
            labels.push(sortData[i].propGrpName)
        }
        setEmptyRoomData({data: data, labels: labels});
    }
    return (
        <div className={styles['top10-dashboard-container']}>
            <Row 
                style={{width: '100%', marginTop: 10}}
                justify="space-evenly"
            >
                <Top10DashboardCard 
                    header={"รายได้ทั้งหมด"} 
                    data={incomeData.data} 
                    labels={incomeData.labels} 
                    datasetLabel={"ยอดเงิน"}
                    backgroundColor={"rgba(211, 211, 211, 0.9)"} 
                    isLoading={isLoading}
                />
                <Top10DashboardCard header={"รับชำระแล้ว"} 
                    data={paidData.data} 
                    labels={paidData.labels} 
                    datasetLabel={"ยอดเงิน"} 
                    backgroundColor={"rgba(133, 243, 98, 0.9)"} 
                    isLoading={isLoading}
                />
                <Top10DashboardCard header={"ค้างชำระ"} 
                    data={overdueData.data} 
                    labels={overdueData.labels} 
                    datasetLabel={"ยอดเงิน"} 
                    backgroundColor={"rgba(234, 85, 75, 0.9)"} 
                    isLoading={isLoading}
                />
                <Top10DashboardCard header={"ห้องที่มีการเช่า"} 
                    data={rentRoomData.data} 
                    labels={rentRoomData.labels} 
                    datasetLabel={"ห้อง"} 
                    backgroundColor={"rgba(31, 114, 249, 0.9)"} 
                    isLoading={isLoading}
                />
                <Top10DashboardCard 
                    header={"ห้องที่ว่าง"} 
                    data={emptyRoomData.data} 
                    labels={emptyRoomData.labels} 
                    datasetLabel={"ห้อง"} 
                    backgroundColor={"rgba(232, 241, 50, 0.9)"} 
                    isLoading={isLoading}
                />
                <Col
                    xs={{ flex: '100%'}}
                    sm={{ flex: '48%' }}
                    md={{ flex: '31%' }}
                    lg={{ flex: '31%' }}
                    xl={{ flex: '31%' }} 
                >
                </Col>
            </Row>
        </div>
    )
}

export default Top10Dashboard;