import { Divider } from "antd";
import styles from "../Dashboard.module.css";
import TextDashboardData from "./TextDashboard/TextDashboardData";
import TextDashboardFooter from "./TextDashboard/TextDashboardFooter";
import { useEffect, useState } from "react";
import moment from "moment";
import { loadReport } from "../../../services/DashboardService";
import { ReportItemGroupByYearMonth, adjustYearMonth, calculateSummary } from "../../../helpers/DataHelper";
import { convertMonthNo2MonthName } from "../../../helpers/DateTimeHelper";

interface TextDashboardProps {
    currentBusiness: "1" | "2";
}
interface MonthYear{
    month: string;
    year: string;
}
const TextDashboard = (props: TextDashboardProps) => {
    const [currentBusiness, setCurrentBusiness] = useState<"1" | "2">(props.currentBusiness);
    const [currentMonthYear, setCurrentMonthYear] = useState<MonthYear | null>(null);
    const [currentDashboardData, setCurrentDashboardData] = useState<ReportItemGroupByYearMonth | null>(null);
    // const []
    useEffect(() => {
        getCurrentMonthYear();
    }, []);

    useEffect(() => {
        if(currentMonthYear != null){
            setCurrentDashboardData(null);
            getReports(currentBusiness, currentMonthYear);
        }
    }, [currentMonthYear, currentBusiness])

    const getCurrentMonthYear = () => {
        let month: string = moment().format("MM");
        let year: string = moment().format("YYYY");
        setCurrentMonthYear({
            month: month,
            year: year
        });
    }
    const getReports = async (currentBusiness: "1" | "2", currentMonthYear: MonthYear) => {
        let resData: any[]  = await loadReport({
                businessId: currentBusiness,
                from: {year: currentMonthYear.year, month: currentMonthYear.month},
                to: {year: currentMonthYear.year, month: currentMonthYear.month}
        });
        
        let resultList: ReportItemGroupByYearMonth[] = adjustYearMonth(resData);
        resultList = calculateSummary(resultList) as ReportItemGroupByYearMonth[];
        let result = resultList[0];
        setCurrentDashboardData(result);
    }
    useEffect(() => {
        setCurrentBusiness(props.currentBusiness)
    }, [props.currentBusiness]);

    const getYearMonthText = () => {
        return currentMonthYear == null ? "" : `${convertMonthNo2MonthName(currentMonthYear.month)} ${parseInt(currentMonthYear.year) + 543}`;
    }
    return (
        <div className={styles['top-dashboard-container']}>
            <h3 style={{paddingLeft: 10, paddingRight: 10, margin: 0}}>
                ประจำเดือน:  
                <span style={{color: 'red', marginLeft: 5}}>{getYearMonthText()}</span>
            </h3>
            <Divider style={{margin: 5}}/>
            <TextDashboardData 
                currentBusiness={currentBusiness} 
                currentDashboardData={currentDashboardData}
            />
            <Divider style={{margin: 5}} />
            <TextDashboardFooter 
                currentBusiness={currentBusiness} 
                currentDashboardData={currentDashboardData}
            />
        </div>
    )
}

export default TextDashboard;