import { useEffect, useState } from 'react';
import styles from '../../Dashboard.module.css';
interface TextDashboardFooterCardProps {
    label: string;
    value: string;
}
const TextDashboardFooterCard = (props: TextDashboardFooterCardProps) => {
    const [label, setLabel] = useState<string>(props.label);
    const [value, setValue] = useState<string>(props.value);

    useEffect(() => {
        setLabel(props.label);
    }, [props.label])
    useEffect(() => {
        setValue(props.value);
    }, [props.value])

    return (
        <div className={styles['text-dashboard-footer-card']}>
            <span className={styles['text-dashboard-footer-card-span']} style={{justifyContent:'flex-end', marginRight: 8}}>{label}</span>
            <span className={styles['text-dashboard-footer-card-span']} style={{justifyContent:'flex-start'}}>{value}</span>
        </div>
    )
}

export default TextDashboardFooterCard;